/* global gtag */
const sendGaEvent = (eventVar, detailVar, nameVar) => {
    console.log(typeof gtag);
    if (typeof gtag != 'undefined') {
        gtag(eventVar, detailVar, {
            'event_category': nameVar
        });
    }
}

const navigateToPage = (e, key, link) => {
    e.preventDefault();
    Gtag.sendGaEvent("event", key, link)
    window.location.href = link
}

export const Gtag = {
    sendGaEvent,
    navigateToPage
};